import { useContext } from 'react';
import './Paginator.scss';
import { SearchContext } from '../../providers/SearchProvider';
import { Link } from 'react-router-dom';


export function Paginator() {

    const { currentPage, pageCount, updateSearch } = useContext(SearchContext);

    const generateBlocks = () => {

        let blocks = [];

        if (pageCount >= 1) {

            if (currentPage - 1 && currentPage > 0) {
                blocks.push({
                    page: currentPage - 1,
                    currentPage: currentPage,
                    label: 'Back'
                });
            }

            // first
            if (currentPage !== 1) {
                blocks.push({
                    page: 1,
                    currentPage: currentPage,
                    label: '1'
                });
            }



            // current
            blocks.push({
                page: currentPage,
                currentPage: currentPage,
                label: currentPage
            });



            // last
            if (pageCount !== currentPage) {
                blocks.push({
                    page: pageCount,
                    currentPage: currentPage,
                    label: pageCount
                });
            }

            // next apge
            if (currentPage + 1 !== currentPage && currentPage + 1 < pageCount) {
                blocks.push({
                    page: currentPage + 1,
                    currentPage: currentPage,
                    label: 'Next'
                });
            }
        }

        // first page
        // next page
        // back a page
        // last page


        return blocks.map((block, i) => {
            return <li key={block.page + block.label}  >
                <Link as={Link} onClick={() => { updateSearch(block.page) }} className={`${block.currentPage === block.page ? 'current' : ''}`}>
                    {block.label}
                </Link>
            </li>
        })
    }

    return (
        pageCount > 1 &&
        <ul className='paginator'>
            {generateBlocks()}
        </ul>
    )
}