import { NavLink } from 'react-router-dom';
import './navbar.css';


export function Navbar() {

    return (
        <header className='navbar'>
            <div className='navbar__links'>
                <NavLink activeClassName="active" to='/'>Discover</NavLink>
                <NavLink activeClassName="active" to='/apply'>Search</NavLink>
                <NavLink activeClassName="active" to="/contact">Contact us</NavLink>
            </div>
            <div className='navbar__logo'>
                Bronte Jobs
            </div>
        </header>
    )
}