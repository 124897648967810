import { useContext, useEffect, useState } from "react";
import { Input } from "../Input/Input";
import './search.scss';
import { SearchContext } from "../../providers/SearchProvider";

export function Search() {

    const baseUrl = 'https://api.brontejobs.com/api/roles/search';
    const [locationList, setLocationList] = useState([]);
    const [locationName, setLocation] = useState('');
    const [timeoutID, setTimeoutID] = useState(null);
    const { updateSearchData, updateKeywords, keywords, updateLocation, location, setSearchUrl, updateIsLoading } = useContext(SearchContext);



    const onKeywordChange = (e) => {
        updateKeywords(e.target.value);
    }

    const onLocationChange = (e) => {

        setLocation(e.nativeEvent.target.value)

        const loc = e.target.value;

        if (loc.length > 3) {
            onLocationSearch(loc);
        }
    }

    const onLocationSearch = (loc) => {
        clearTimeout(timeoutID);
        setTimeoutID(setTimeout(async () => {
            const url = getLocationUrl(loc);
            const locationResults = await fetch(url).then(o => o.json());
            if (locationResults.length > 0) {
                updateLocation(loc, locationResults[0].lat, locationResults[0].lon)
                setLocationList(locationResults);
            }
        }, 500));
    }

    const getLocationUrl = (loc) => {
        return `https://eu1.locationiq.com/v1/search?key=pk.3a7fcf4bf584d81f2f0a82062799c13d&q=${loc}&format=json&countrycodes=gb&normalizeaddress=1`;
    }

    const onSearch = async (e) => {
        e.preventDefault();
        updateIsLoading(true);
        updateSearchData([])
        console.log(location);
        let url = `${baseUrl}?keywords=${keywords}`;
        if (location?.lat && location?.lng) {
            url += `&lat=${location.lat}&lng=${location.lng}&distance=10000`
        }

        setSearchUrl(url);

        await fetch(url).then(p => p.json()).then(o => {
            updateSearchData(o);
            updateIsLoading(false);
        })
    }


    return (
        <form className="search" onSubmit={(e) => { onSearch(e) }}>
            <div className="search__inner">
                <Input placeholder={'Keywords'} value={keywords} onChange={onKeywordChange}></Input>
                {/* <Input placeholder={'Postcode'} value={locationName} onChange={onLocationChange}></Input> */}
                <div className="f-r">
                    <button type="submit" className="brand">Search</button>
                </div>
            </div>
        </form>

    )
}