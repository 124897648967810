import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';


export function Transitions() {
  const location = useLocation();
  const prevLocationRef = useRef(null);
  const [exitAnimation, setExitAnimation] = useState(false);

  useEffect(() => {
    if (document.startViewTransition) {
      const htmlTag = document.getElementsByTagName('html')[0];
      if (location.pathname.includes('/apply/') && exitAnimation === false) {
        document.startViewTransition(() => {
          htmlTag.classList.remove('exit');
          setExitAnimation(true)
        });
      } else if (exitAnimation === true) {
        htmlTag.classList.add('exit');
        document.startViewTransition(() => {
          setExitAnimation(false)
        });
      }
    }
    prevLocationRef.current = location;

  }, [location.pathname]);

  const prevLocation = prevLocationRef.current;

  return <Outlet />;
}